.skybanner{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    /* top: 30px; */
    /* margin-bottom: 80px !important; */
}

.skymain{
    height: 100%;
    width: 100%;
}

.imgSection{
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    height: 100%;
}

.skyimg{
    height: calc(100vh - 100px);
    width: 100%;
    object-fit: cover;
}

.skycontent{
    position: relative;
    bottom: 78vh;
    left: 10vw;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    width: 40vw;
}

.sky1{
    position: absolute;
    bottom: 0;
    left: 15vw;
    width: 80vw;
    height: 90vh;
    z-index: 5;
}

@media only screen and (min-width: 1300px) {
    .skybanner{
        /* margin-bottom: 20px; */
    }
}

@media only screen and (max-width: 768px) {
    /* .skycontent{
        width: ;
    } */
    /* .sky1 {
        bottom: 5vh;
    } */
}