.card {
    background-color: transparent;
    border: none;
}

.maindiv {
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 70px;
    padding-right: 70px;
    background-color: #F2F2F2;
}

.imgwidth {
    width: auto;
}

.headingfont {
    font-size: 32px;
    font-weight: 700;
    color: #08132D;
}

.content {
    color: #4C5877;
    font-size: 16px;
    font-weight: 400;
}

.subcontent {
    color: #4C5877;
    font-size: 14px;
    font-weight: 400;
}

.learnmorebtn,
.learnmorebtn:hover {
    width: fit-content;
    background-color: #265EE1;
    color: #FFFFF0;
    margin-top: 3rem;
}

.imgheadingfont {
    font-size: 0.90vw;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: start;
}

.imgcontent {
    font-size: 0.75vw;
    font-weight: 500;
    text-align: start;
    margin-bottom: 10px;

}

.contentbox1 {
    box-shadow: 0px 4px 40px 0px #D8D8D840;
    background-color: white;
    width: 12vw;
    padding: 15px;
    border-radius: 19px;
    position: absolute;
    top: 200px;
    left: 160px;
}

.contentbox2 {
    box-shadow: 0px 4px 40px 0px #D8D8D840;
    background-color: white;
    width: 12vw;
    padding: 15px;
    border-radius: 19px;
    position: absolute;
    bottom: 100px;
    left: 160px;
}

.contentbox3 {
    box-shadow: 0px 4px 40px 0px #D8D8D840;
    background-color: white;
    width: 12vw;
    padding: 15px;
    border-radius: 19px;
    position: absolute;
    right: 100px;
    top: 300px;
}

@media only screen and (max-width: 2000px) {
    .maindiv {
        display: flex;
    }

}

@media only screen and (min-width: 2000px) {
    .maindiv {
        display: inline;
    }

    .contentbox1 {
        top: 200px;
    }

    .contentbox2P {
        bottom: 200px;

    }

    .contentbox3 {
        top: 500px;
    }

    .imgwidth {
        width: 40% !important;
    }
}

@media only screen and (max-width: 1500px) {
    .contentbox3 {
        right: 100px;
    }
}

@media only screen and (max-width: 1350px) {
    .headingfont {
        font-size: 28px;
    }

    .contentbox3 {
        right: 30px;
    }
}

@media only screen and (max-width: 1200px) {
    .contentbox1 {
        top: 200px;
        left: 100px;
    }

    .contentbox2 {
        left: 100px;
    }
}

@media only screen and (max-width: 900px) {
    .contentbox1 {
        top: 150px;
        left: 100px;
    }

    .maindiv {
        padding: 20px;
    }

    .contentbox2 {
        left: 100px;
        bottom: 150px;
    }

    .headingfont {
        font-size: 26px;
    }

    .subcontent {
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }

    .content {
        font-size: 14px;
        margin-top: 10px !important;
    }
}

@media only screen and (max-width: 768px) {
    .contentbox1 {
        width: 30vw;
    }


    .contentbox2 {
        width: 30vw;
    }

    .contentbox3 {
        width: 30vw;
    }

    .imgheadingfont {
        font-size: 2vw;
    }

    .imgcontent {
        font-size: 1.5vw;
    }

    .contentsec {
        margin-top: 3rem;
    }

    .content {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
}

@media only screen and (max-width: 600px) {
    .maindiv {
        padding-left: 20px;
        padding-right: 20px;
    }

    .headingfont {
        font-size: 25px;
    }

    .subcontent {
        font-size: 12px;
    }
}

@media only screen and (max-width: 425px) {
    .headingfont {
        font-size: 20px;
    }

    .imgwidth {
        width: 100%;
    }

    .contentbox1,
    .contentbox2 {
        left: 75px;
    }

    .contentbox3 {
        top: 220px;
    }
}

@media only screen and (max-width: 400px) {
    .imgcontent {
        margin-bottom: 5px;
    }

    .contentbox1 {
        top: 130px;
        left: 70px;
        padding: 12px;
    }

    .contentbox2 {
        bottom: 90px;
        left: 70px;
        padding: 12px;
    }

    .contentbox3 {
        top: 220px;
        padding: 12px;
    }
}

@media only screen and (max-width: 320px) {
    .contentbox1 {
        top: 100px;
        left: 30px;
        padding: 10px;
        width: 40vw;
    }

    .contentbox2 {
        bottom: 40px;
        left: 30px;
        padding: 10px;
        width: 40vw;

    }

    .imgheadingfont {
        font-size: 10px;
    }

    .imgcontent {
        margin-bottom: 0;
        font-size: 8px;
    }

    .contentbox3 {
        top: 150px;
        right: 0;
        width: 40vw;
    }
}

@media only screen and (max-width: 265px) {
    .contentbox1 {
        top: 80px;
        left: 0;
    }

    .contentbox2 {
        bottom: 40px;
        left: 0;
    }

    .contentbox3 {
        top: 130px;
    }
}