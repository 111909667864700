.livebanner{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    top: 60px;
}

.livemain{
    height: 100vh;
    width: 100%;
}

.imgSection{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 105vh;
}

.liveimg{
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.livecontent{
    position: relative;
    bottom: 67vh;
    left: 40vw;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    width: 50vw;
}

@media only screen and (max-width: 768px) {
    .livecontent{
        bottom: 71vh;
        left: 45vw;
    }
    .livecontent h1{
        font-size: 4vh !important;
    }
    .livecontent h5{
        font-size: 2vh !important;
    }
    .livecontent p{
        font-size: 1vh;
    }
}