.maindiv {
    padding-left: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 50%;
    background-color: #F2F2F2;
    position: relative;
    top: 0%;
}

.learnmorebtn {
    background-color: #265EE1;
    color: white;
    width: fit-content;
}

.headingfont {
    color: #08132D;
    font-size: 34px;
    font-weight: 700;
    padding-right: 20px;
}

.content {
    color: #4C5877;
    font-size: 18px;
    font-weight: 400;
    padding-right: 30px;
}

.card {
    text-align: start;
    border: none;
    border-radius: 19px;
    width: 360px !important;
    margin-right: 0 !important;
}

.cardbox {
    border: 0 !important;
    border-radius: 19px;
    padding: 20px;
}

.imagegradient {
    position: absolute;
    width: 13%;
    height: 100%;
    background: linear-gradient(270deg, #F2F2F2 17.6%, rgba(242, 242, 242, 0) 100%);
    border-radius: 8px;
    z-index: 2;
    right: 0;
}

.cardcontent {
    color: #4C5877;
    font-size: 14px;
    font-weight: 400;
}

.cardheading {
    font-size: 18px;
    font-weight: 600;
}

.roundedImage {
    border-radius: 50% !important;
}

.cardimg {
    height: 30%;
    width: 30%;
}

.cardtext {
    height: 60%;
    overflow: hidden;
}

.cardbtn {
    margin-top: 5px;
    height: 20%;
}

.btntext {
    border-bottom: 1px solid #265EE1;
    color: #265EE1;
    font-size: 14px;
    font-weight: 500;
}

@media only screen and (min-width: 2000px) {
    .card {
        width: 600px !important;
    }

    .content {
        font-size: 1vw;
    }

    .cardcontent {
        font-size: 0.7vw;
    }

    .headingfont {
        font-size: 1.3vw;
    }
.learnmorebtn{
    font-size: 0.75vw;
}
    .btntext {
        font-size: 0.75vw;
    }

    .cardheading {
        font-size: 1vw;
    }

    .cardbox {
        height: 70%;
    }

    .cardimg {
        height: 35%;
    }

    .cardtext {
        height: 80%;
    }
}


@media only screen and (max-width: 2000px) {
    .cardbox {
        height: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        position: relative;
        top: -20%;
    }

    .headingfont {
        font-size: 28px;
    }

    .cardtext {
        height: 80%;
    }

    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        font-size: 16px;
    }
}

@media only screen and (max-width: 510px) {
    .maindiv {
        position: relative;
        top: -30%;
    }
}

@media only screen and (max-width: 425px) {
    .card {
        width: 340px !important;
    }

    .cardtext {
        height: 60%;
    }

    .imagegradient {
        display: none;
    }
}