.maindiv {
    height: 20%;
    padding: 10px;
    padding-left: 60px;
    padding-top: 30px;
    margin-bottom: 10px;
    padding-right: 60px;
}

.heading {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #939DB4;
    margin-bottom: 0 !important;
}

.brands {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 40px;
    width: 100px;
}

@media only screen and (max-width: 1200px) {
    .maindiv {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 990px) {
    .maindiv {
        padding: 20px;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    .brands {
        margin: 1.5rem;
        height: 40px;
        width: 80px;
    }
}

@media only screen and (max-width: 600px) {
    .brands {
        margin: 1.5rem;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv{
        height: 15%;
    }
    .brands {
        margin: 1rem;
        height: 30px;
        width: 70px;
    }
}