@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  box-sizing: border-box;
}

.row {
  --bs-gutter-x: 0 !important;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  background-color: #FAFAFA;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  color: #000;
}

.btn:focus {
  box-shadow: none;
}

h1,
a,
button {
  font-family: Montserrat, sans-serif;
}

p {
  font-family: 'Open Sans', sans-serif;
}

.carousel-indicators [data-bs-target] {
  background-color: #265EE1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  bottom: -44px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px !important;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #0000003D;
  border-radius: 50px;
}

.swiper {
  z-index: auto !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 48px !important;
  height: 48px !important;
}

.rsm-svg .rsm-marker path {
  fill: #F74445 !important;
  stroke: none !important;
}

.btn-check:checked+.btn-outline-primary {
  color: white;
  background-color: #265EE1;
  border: none;
}

.btn-outline-primary:hover {
  color: white;
  background-color: #265EE1;
  border: none;
}

.btn-check:focus+.btn {
  box-shadow: none;
}

.btn-check:focus+.btn-outline-primary {
  box-shadow: none;
}

.form-control:focus {
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}

.offcanvas-end {
  width: 325px;
}

#regexeprience .swiper-button-disabled {
  display: none;
}

#allproduct,
#regexeprience,
#map,
#brandandprocess {
  height: 100vh;
  position: sticky;
}

#homepage {
  height: 90% !important;
}

.homeslider {
  width: 100%;
  height: 100% !important;
}

.sliderimg {
  object-fit: cover;
}

.sliderimage {
  height: calc(100vh - 100px);
}

.carousel-item {
  height: 100%;
}

@media only screen and (max-width: 600px) {
  #map {
    height: 90vh;
  }
}

@media only screen and (max-width: 425px) {
  .offcanvas-end {
    width: 275px;
  }

  #ourclient .swiper-button-next,
  #ourclient .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 65%) !important;
  }

  #regexeprience .swiper-button-disabled {
    display: inline;
  }
}

@media only screen and (max-width: 375px) {
  .offcanvas-end {
    width: 275px;
  }

}