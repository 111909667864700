.outletbg {
    background-color: #DDE6ED;
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 768px) {
    .outletbg {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 1024px) {
    .outletbg {
        overflow: hidden !important;
    }
}