.products {
    /* position: sticky; */
    top: 0px;
    z-index: 111;
    margin-top: 70px;
    padding-top: 20px;
    padding-bottom: 0px;
    background-color: #4C5877;
    width: 98.5vw;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headingfont {
    color: #08132D;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
}

.content {
    color: #4C5877;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .headingfont {
       font-size: 30px;
    }
}
@media only screen and (max-width: 900px) {
    .headingfont {
       font-size: 25px;
    }
    .content{
        font-size: 16px;
    }
}
@media only screen and (max-width: 425px) {
    .headingfont {
       font-size: 20px;
    }
    .content{
        font-size: 12px;
    }
}