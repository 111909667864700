.mainlogin {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #DDE6ED;
  }
  
  .loginform {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    padding: 20px;
  }
  
  .logoImage {
    height: 80px;
    width: auto;
    /* Allow the width to adjust proportionally */
  }
  
  .backcolor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100vh;
    background-color: #DDE6ED;
  }
  
  .errormsg {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .loginButton:hover {
    color: #f2f4f9;
  }
  
  .loginimg {
    max-width: 100%;
    height: auto;
    margin: 20px;
  }
  
  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25), 0 0 0 1000px rgba(255, 255, 255, 0.5) inset, -10px 0 10px -8px rgba(0, 0, 0, 0.5) !important;
  }
  
  .form-control::placeholder {
    font-size: 14px;
  }
  
  
  .eybtn {
    padding-left: 10px;
    background-color: transparent;
    border: none;
    background-color: #f2f4f9;
    padding-right: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .loginButton {
    width: 100%;
    border-radius: 10px;
    background-color: #27374D;
    color: #f2f4f9;
  }
  
  .reset:hover{
    color: #0d6efd !important;
  }
  
  @media screen and (max-width: 768px) {
    .loginform {
      width: 100%;
      /* Adjust the width for smaller screens */
    }
  
    .backcolor {
      width: 60%;
      display: none;
    }
  }
  
  @media screen and (max-width: 480px) {
    .loginform {
      width: 100%;
      /* Adjust the width for smaller screens */
      padding-left: 0;
      padding-right: 0;
    }
  
    .logincontent {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  
    .backcolor {
      width: 100%;
      /* Fill the entire width on smaller screens */
    }
  }