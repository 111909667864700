.herobanner {
    /* height: calc(100vh - 100px); */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    height: 100%;
    /* top: 55px; */
    /* margin-bottom: 10vh; */
}

.heromain {
    height: 100%;
    width: 100%;
}

.imgSection {
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    height: 100%;
}

.heroimg {
    height: calc(100vh - 100px);
    width: 100%;
    object-fit: cover;
}

.herocontent {
    position: absolute;
    bottom: 10%;
    left: 20%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textimg {
    width: 60vw;
}

.quoteimg {
    width: 40vw;
}

.authorimg {
    width: 8vw;
}

@media only screen and (min-width: 1300px) {
    .herobanner {
        /* margin-bottom: 20px; */
    }
}

@media only screen and (max-width: 768px) {
    .herobanner {
        /* margin-bottom: 70px; */
    }

    .quoteimg {
        width: 50vw;
    }

    .authorimg {
        width: 10vw;
    }
}

@media only screen and (max-width: 510px) {
    .herobanner {
        /* margin-bottom: 60px; */
    }

    .quoteimg {
        width: 60vw;
    }

    .authorimg {
        width: 12vw;
    }
}