.gamebanner{
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -20;
    /* top: 40px; */
    /* margin-bottom: 6vh; */
}

.gamemain{
    height: 100%;
    width: 100%;
}

.imgSection{
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    height: 100%;
}

.gameimg{
    height: calc(100vh - 100px);
    width: 100%;
    object-fit: cover;
}

.gamecontent{
    position: relative;
    bottom: 72vh;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textimg{
    width: 50vw;
}

.quoteimg{
    width: 40vw;
}

.authorimg{
    width: 8vw;
}

.game1{
    position: absolute;
    top: 10vh;
    left: 2vw;
    width: 80vw;
    height: 60vh;
}

.game2{
    position: absolute;
    top: 10vh;
    left: 15vw;
    width: 80vw;
    height: 64vh;
}
@media only screen and (min-width: 1300px) {
    .gamebanner{
        /* margin-bottom: 20px; */
    }
}

@media only screen and (max-width: 768px) {
    .gamebanner{
        /* margin-bottom: 70px; */
    }
}