.maindiv {
    height: 100%;
    padding: 50px;
}

.headingfont {
    color: #08132D;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clientheading {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    color: #4C5877;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.clientslider {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activecontentclient {
    color: #FAFAFA;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.contentclient {
    color: #08132D;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.notactive {
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 50px;
    padding-bottom: 0 !important;
}

.active {
    background-color: #265EE1;
    height: 35%;
    border-radius: 8px;
    position: relative;
    margin: 60px;
    display: flex;
    justify-content: center;
}

.clientimg {
    border-radius: 50px;
    height: 90px !important;
    width: 90px !important;
}

.clientsec {
    position: absolute;
    top: 85%;
}

.clientcontent {
    color: #4C5877;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.clientname {
    color: #2D2E2E;
    font-size: 16px;
    font-weight: 600;
}

.imagegradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    border-radius: 50px;
    z-index: 1;
}

.blackAndWhite {
    filter: grayscale(100%);
}

@media only screen and (max-width: 1600px) {
    .active {
        margin: 30px;
        height: 40%;
    }

    .notactive {
        height: 60%;
    }
}

@media only screen and (max-width: 1200px) {
    .maindiv {
        padding: 20px;
    }
}

@media only screen and (max-width: 1400px) {
    .active {
        margin: 0px;
        height: 40%;
    }

    .activecontentclient {
        font-size: 14px;
    }

    .notactive {
        margin-left: 10px;
        margin-right: 10px;
    }

    .clientcontent {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .activecontentclient {
        overflow: hidden;
    }
}

@media only screen and (max-width: 600px) {
    .headingfont {
        font-size: 25px;
    }

    .maindiv {
        height: 60vh;
    }

    .content {
        font-size: 14px;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        height: 60vh;
    }

    .clientheading {
        height: 30%;
    }

    .clientslider {
        height: 70%;
    }
}

@media only screen and (max-width: 320px) {
    .headingfont {
        font-size: 20px;
    }

    .content,
    .activecontentclient {
        font-size: 12px;
    }
    .clientimg{
        height: 80px !important;
        width: 80px !important;
    }
}